import React from "react";
import styles from "./styles.module.scss";
import { ReactComponent as Logo } from "./logo.svg";
import { ReactComponent as NewsFeed } from "./newsfeeds.svg";
import { ReactComponent as JSONLayers } from "./jsonlayers.svg";
import { ReactComponent as MockupRaw } from "./mockup.svg";
import { ReactComponent as AILogo } from "./ai-icon.svg";
import { ReactComponent as Machine } from "./machine.svg";
import { ReactComponent as ProgrammingUser } from "./programming-user.svg";
import { ReactComponent as Swoosh } from "./swoosh.svg";
import { ReactComponent as Shapes } from "./shapes.svg";

function Mockup() {
  return (
    <div id={styles.mockup}>
      <Shapes />
      <MockupRaw />
    </div>
  );
}

function ContentSection(props: {
  illustration: React.ReactElement;
  title: string;
  logo: React.ReactElement;
  body: React.ReactElement;
}) {
  return (
    <div className={styles.content}>
      <div className={styles.illustration}>{props.illustration}</div>
      <div className={styles.contentBody}>
        <div className={styles.logoImg}>{props.logo}</div>
        <h2>
          <span className={styles.sketchUnderline}>
            {props.title}
            <Swoosh />
          </span>
        </h2>
        {props.body}
      </div>
    </div>
  );
}

function LandingPage() {
  const handleClick = () => {
    return window.location.assign(`${process.env.REACT_APP_BASE_URL}`);
  };

  return (
    <div className={styles.container}>
      <header>
        <div>
          <div id={styles.logo}>
            <Logo />
          </div>
          <button id={styles.login} onClick={handleClick}>
            Login
          </button>
          <h1 id={styles.headline}>Only the news you need, first</h1>
          <h3 id={styles.kicker}>
            Europe's fastest financial news feed - now part of{" "}
            <a href="https://www.reutersagency.com/en/media-center/reuters-welcomes-plx-ai/">
              Reuters News
            </a>
          </h3>
        </div>
      </header>
      <main>
        <div>
          <ContentSection
            illustration={<NewsFeed />}
            logo={<AILogo />}
            title="Powered by AI"
            body={
              <p>
                Our algorithms understand financial texts the way a human would.
                PLX AI automatically extracts and delivers the news that moves
                stocks. There is nobody faster.
              </p>
            }
          />
          <ContentSection
            illustration={<Mockup />}
            logo={<ProgrammingUser />}
            title="Trade the news"
            body={
              <p>
                Be first to react to surprise earnings, outlook changes, M&A,
                important orders, or management decisions from 1,600 companies
                in U.S. & Europe.
              </p>
            }
          />
          <ContentSection
            illustration={<JSONLayers />}
            logo={<Machine />}
            title="We serve machines"
            body={
              <p>
                PLX AI is also available as a machine-readable feed, exclusively
                through Refinitiv. Contact us or your Refinitiv representative
                for details.
              </p>
            }
          />
        </div>
      </main>
      <div className={styles.contact}>
        <div>
          <h3>Get in touch</h3>
          <a href="mailto:contact@plx.ai">contact@plx.ai</a>
        </div>
      </div>
    </div>
  );
}

export default LandingPage;
